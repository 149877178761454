.container-urna {
    width: 100%;
    height: auto;
    min-height: calc(100vh - 70px);
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--eight);
    position: relative;
    top: 70px;
}

.container-urna.sem-navbar {
    min-height: 100vh !important;
    top: 0px !important;
}

.content-urna {
    width: 92.5%;
    max-width: 1100px;
    height: auto;
    margin: 20px auto;
}

.content-urna .card {
    width: 100%;
    height: auto;
    border-radius: 10px;
    border: 2px solid var(--seven);
    background: var(--white);
    margin: 35px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content-urna .card .sobre {
    width: 95%;
    max-width: 95%;
    height: 55px;
    max-height: 55px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin: 0px auto;
    user-select: none;
}

.content-urna .card .sobre ion-icon {
    font-size: 28px;
} .content-urna .card .sobre ion-icon.verde {
    color: var(--green);
} .content-urna .card .sobre ion-icon.vermelho {
    color: var(--red);
} .content-urna .card .sobre ion-icon.amarelo {
    color: var(--yellow);
}

.content-urna .card .sobre h1 {
    font-size: 17px;
    font-weight: 600;
    color: var(--three);
    pointer-events: none;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.content-urna .card .sobre h1 .numero {
    width: 30px;
    height: 30px;
    padding: 5px;
    font-size: 16px;
    font-weight: 700;
    color: var(--white);
    border-radius: 50%;
    background: var(--bluelight);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 8px 0px 0px;
}

.content-urna .card .linha {
    width: 100%;
    height: auto;
    border-top: 2px solid var(--seven);
}

.content-urna .card .gerenciar {
    width: 95%;
    max-width: 95%;
    position: relative;
    height: auto;
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.content-urna .card .gerenciar h1 {
    font-size: 18px;
    font-weight: 700;
    color: var(--three);
    pointer-events: none;
    display: block;
    flex-direction: row;
    align-items: center;
    margin: 10px auto;
    cursor: text;
    pointer-events: all;
} .content-urna .card .gerenciar h1 .azul {
    color: var(--bluelight);
    margin: 0px 4px;
}

.content-urna .card .gerenciar p {
    font-size: 17px;
    font-weight: 600;
    color: var(--three);
    pointer-events: none;
    display: block;
    flex-direction: row;
    align-items: center;
    margin: 10px auto;
    cursor: text;
    pointer-events: all;
} .content-urna .card .gerenciar p .azul {
    color: var(--bluelight);
    margin: 0px 4px;
}



.content-urna .card .gerenciar .flex {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.content-urna .card .gerenciar .formulario .input {
    margin: 5px auto;
}

.content-urna .card .gerenciar .formulario .input input {
    margin: 12.5px auto;
}

.content-urna .card .gerenciar button {
    width: auto;
    height: 50px;
    padding: 0px 20px;
    background: var(--blue);
    box-shadow: 4px 4px 2px var(--bluelight);
    border: 2px solid var(--blue);
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.2px;
    color: var(--white);
    margin: 0px auto 0px 20px;
    cursor: pointer;
    border-radius: 5px;
    outline: none;
    transition: all .1s ease-in-out;
} .content-urna .card .gerenciar button:hover,
 .content-urna .card .gerenciar button:focus {
    background: transparent;
    color: var(--blue);
    box-shadow: 4px 4px 2px var(--blue);
}

.content-urna .card .gerenciar .btn-vermelho {
    background: var(--red);
    border: 2px solid var(--red);
    box-shadow: 4px 4px 2px red;
    cursor: not-allowed;
} .content-urna .card .gerenciar .btn-vermelho:hover,
 .content-urna .card .gerenciar .btn-vermelho:focus {
    background: transparent;
    color: var(--red);
    box-shadow: 4px 4px 2px var(--red);
}

.content-urna .card .gerenciar .btn-verde {
    background: var(--green);
    border: 2px solid var(--green);
    box-shadow: 4px 4px 2px green;
} .content-urna .card .gerenciar .btn-verde:hover,
 .content-urna .card .gerenciar .btn-verde:focus {
    background: transparent;
    color: var(--green);
    box-shadow: 4px 4px 2px var(--green);
}


.content-urna .card .gerenciar .btn-laranja {
    background: var(--orange);
    border: 2px solid var(--orange);
    box-shadow: 4px 4px 2px orange;
} .content-urna .card .gerenciar .btn-laranja:hover,
 .content-urna .card .gerenciar .btn-laranja:focus {
    background: transparent;
    color: var(--orange);
    box-shadow: 4px 4px 2px var(--orange);
}

/* ESCOLAS */
.content-urna .card .gerenciar .column.escola {
    width: max-content !important;
    height: auto;
    margin: 12.5px 0px;
}

.content-urna .card .gerenciar .column.escola h1 {
    font-size: 19px;
    font-weight: 700;
    margin: 2.5px 0px;
    color: var(--three);
}

.content-urna .card .gerenciar .column.escola p {
    font-size: 16px;
    font-weight: 500;
    margin: 2.5px 0px;
    color: var(--three);
}



/* ----- URNA ----- */
.urna {
    width: 100%;
    height: auto;
    min-height: calc(100vh);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 20px 0px;
}

/* TELA */
.urna .tela {
    width: 50%;
    height: auto;
    min-height: calc(90vh - 70px);
    display: flex;
    flex-direction: column;
    color: var(--four);
    /*align-items: center;*/
}

.urna .tela h1 {
    font-size: 28px;
    font-weight: 700;
    width: 90%;
    margin: 0px;
    padding: 0px;
    color: var(--four);
    margin: 12.5px 0px;
}

.urna .tela .linha {
    width: 100%;
    height: 2px;
    background: var(--four);
    margin: 0px auto;
}

.urna .tela .flex .column h2 {
    font-size: 22px;
    font-weight: 700;
    margin: 0px;
    padding: 0px;
    color: var(--four);
    margin: 20px 0px 0px 0px;
}

.urna .tela .flex .fim {
    font-size: 50px;
    font-weight: 700;
    color: var(--three);
    letter-spacing: 0.2px;
    margin: 50px auto;
}
/* VOTO */
.urna .tela .flex .column .flex .voto {
    padding: 15px;
    border: 2px solid var(--four);
    margin: 0px 20px 0px 0px;
    font-size: 60px;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    color: var(--four);
}

/* IMAGEM */
.urna .tela .flex .column .imagem {
    width: 190px;
    height: 175px;
    border: 2px solid var(--four);
    margin: 5px 0px 40px 0px;
    position: relative;
}

.urna .tela .flex .column .imagem img {
    width: 100%;
    height: 100%;
    position: relative;
}

.urna .tela .flex .column .imagem p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 100px;
    font-weight: 500;
}

.urna .tela .flex .column p {
    font-size: 18px;
    font-weight: 600;
    margin: 0px;
    padding: 0px;
    color: var(--four);
    margin: 0px 0px 15px 0px;
}

.urna .tela .instrucao {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 10px 0px 0px 0px;
}

.urna .tela .instrucao p {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.2px;
    color: var(--two);
}


/* BOTÕES */
.urna .botoes {
    width: 35%;
    max-width: 500px;
    height: auto;
    min-height: calc(90vh - 70px);
    display: flex;
    flex-direction: column;
    color: var(--four);
    background: var(--two);
    border: 3px solid var(--two);
    /*align-items: center;*/
}

.urna .botoes .informacoes {
    width: auto;
    height: auto;
    background: var(--nine);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 0px 15px 0px;
}

.urna .botoes .informacoes h1 {
    width: 92.5%;
    font-size: 18px;
    font-weight: 700;
    margin: 0px;
    padding: 0px;
    color: var(--three);
    margin: 10px 0px 0px 0px;
}


.urna .botoes .informacoes p {
    width: 92.5%;
    font-size: 16px;
    font-weight: 600;
    margin: 0px;
    padding: 0px;
    color: var(--three);
    margin: 0px 0px 0px 0px;
}

/* BOTOES BTNS */
.urna .botoes .btns {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto 0px;
    padding: 0px 0px 10px 0px;
}

.urna .botoes .btns .column {
    width: 100%;
    height: auto;
    display: flex;
    margin: 10px 0px;
}

.urna .botoes .btns .flex {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
}

.urna .botoes .btns .flex .btn-numero {
    background: var(--white);
    color: var(--three);
    width: 60px;
    height: 60px;
    margin: 0px 15px;
    box-shadow: 4px 4px 2px var(--four);
    font-size: 25px;
    font-weight: 600;
    cursor: pointer;
    transition: all .1s ease-in-out;
} .urna .botoes .btns .flex .btn-numero:active,
.urna .botoes .btns .flex .btn-numero:focus {
  box-shadow: none;
  transform: translate(4px, 4px);
}

.urna .botoes .btns .flex .btn-votar {
    background: var(--white);
    color: var(--three);
    width: max-content;
    height: max-content;
    margin: 0px 15px;
    text-transform: uppercase;
    padding: 10px 15px;
    font-size: 17px;
    font-weight: 600;
    box-shadow: 4px 4px 2px var(--four);
    cursor: pointer;
    transition: all .1s ease-in-out;
} .urna .botoes .btns .flex .btn-votar:active,
  .urna .botoes .btns .flex .btn-votar:focus {
    box-shadow: none;
    transform: translate(4px, 4px);
}

.urna .botoes .btns .flex .btn-votar.branco {
    box-shadow: 4px 4px 2px var(--six);
} .urna .botoes .btns .flex .btn-votar.branco:active,
  .urna .botoes .btns .flex .btn-votar.branco:focus {
    box-shadow: none;
    transform: translate(4px, 4px);
}

.urna .botoes .btns .flex .btn-votar.corrige {
    background: var(--orange);
    box-shadow: 4px 4px 2px #ff9249;
} .urna .botoes .btns .flex .btn-votar.corrige:active,
  .urna .botoes .btns .flex .btn-votar.corrige:focus {
    box-shadow: none;
    transform: translate(4px, 4px);
}

.urna .botoes .btns .flex .btn-votar.confirma {
    background: var(--green);
    box-shadow: 4px 4px 2px #1cfd1c;
} .urna .botoes .btns .flex .btn-votar.confirma:active,
  .urna .botoes .btns .flex .btn-votar.confirma:focus {
    box-shadow: none;
    transform: translate(4px, 4px);
}

@media (max-width: 1222px) {
    .urna {
        flex-direction: column;
    }

    .urna .tela {
        width: 90%;
        max-width: 500px;
        margin: 0px auto;
    }

    .urna .botoes {
        width: 90%;
        max-width: 500px;
        margin: 35px auto;
    }
}