.container-erro {
    width: 100%;
    height: auto;
    min-height: calc(100vh);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--eight);
}

.container-erro img {
    width: 80%;
    max-width: 400px;
    height: auto;
    margin: -30px 0px 0px 0px;
}

.container-erro h1 {
    color: var(--blue);
    font-size: min(calc(10px + 2.8vw), 28px);
    font-weight: 800;
    text-align: center;
    text-shadow: 1px 1px 2px var(--bluelight);
    margin: -30px 0px 17.5px 0px;
}

.container-erro a {
    width: max-content;
    height: max-content;
    color: var(--light);
    background: var(--blue);
    border: 2px solid var(--blue);
    padding: 10px 16px;
    border-radius: 5px;
    box-shadow: 4px 4px 2px var(--bluelight);
    font-size: 15px;
    font-weight: 700;
    transition: all .2s ease-in-out;
} .container-erro a:hover,
.container-erro a:focus {
    background: transparent;
    color: var(--blue);
    box-shadow: 4px 4px 2px var(--blue);
}