.container-inicio {
    width: 100%;
    height: auto;
    min-height: calc(100vh - 70px);
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--eight);
    position: relative;
    top: 70px;
}

.content-inicio {
    width: 92.5%;
    max-width: 1100px;
    height: auto;
    margin: 20px auto;
}

.content-inicio .card {
    width: 100%;
    height: auto;
    border-radius: 10px;
    border: 2px solid var(--seven);
    background: var(--white);
    margin: 35px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content-inicio .card.vermelho {
    background: var(--red);
    border: 2px solid red;
    color: var(--white);
}


.content-inicio .card.vermelho .sobre h1 {
    color: var(--white);
} .content-inicio .card.vermelho .sobre ion-icon {
    color: var(--white);
} .content-inicio .card.vermelho .gerenciar h1 {
    color: var(--white);
}  .content-inicio .card.vermelho .gerenciar h1 strong {
    color: var(--eight);
    font-size: 20px;
}


.content-inicio .card.vermelho .linha {
    border-top: 2px solid red;
}


.content-inicio .card .sobre {
    width: 95%;
    max-width: 95%;
    height: 55px;
    max-height: 55px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin: 0px auto;
    user-select: none;
}

.content-inicio .card .sobre ion-icon {
    font-size: 28px;
} .content-inicio .card .sobre ion-icon.verde {
    color: var(--green);
} .content-inicio .card .sobre ion-icon.vermelho {
    color: var(--red);
} .content-inicio .card .sobre ion-icon.amarelo {
    color: var(--yellow);
}

.content-inicio .card .sobre h1 {
    font-size: 17px;
    font-weight: 600;
    color: var(--three);
    pointer-events: none;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.content-inicio .card .sobre h1 .numero {
    width: 30px;
    height: 30px;
    padding: 5px;
    font-size: 16px;
    font-weight: 700;
    color: var(--white);
    border-radius: 50%;
    background: var(--bluelight);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 8px 0px 0px;
}

.content-inicio .card .linha {
    width: 100%;
    height: auto;
    border-top: 2px solid var(--seven);
}

.content-inicio .card .gerenciar {
    width: 95%;
    max-width: 95%;
    position: relative;
    height: auto;
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.content-inicio .card .gerenciar h1 {
    font-size: 18px;
    font-weight: 700;
    color: var(--three);
    pointer-events: none;
    display: block;
    flex-direction: row;
    align-items: center;
    margin: 10px auto;
    cursor: text;
    pointer-events: all;
} .content-inicio .card .gerenciar h1 .azul {
    color: var(--bluelight);
    margin: 0px 4px;
}

.content-inicio .card .gerenciar p {
    font-size: 17px;
    font-weight: 600;
    color: var(--three);
    pointer-events: none;
    display: block;
    flex-direction: row;
    align-items: center;
    margin: 10px auto;
    cursor: text;
    pointer-events: all;
} .content-inicio .card .gerenciar p .azul {
    color: var(--bluelight);
    margin: 0px 4px;
}

/* GERENCIAR CHAPA */
.content-inicio .card .gerenciar .gerenciar-chapa {
    width: 100%;
    height: auto;
    border: 2px solid var(--seven);
    border-radius: 10px;
    margin: 0px 0px 17.5px auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px 15px 20px 15px;
}


.content-inicio .card .gerenciar .gerenciar-chapa .column {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0px 0px 0px 5px;
}


.content-inicio .card .gerenciar .gerenciar-chapa .column p {
    width: 100%;
    margin: 0px;
}

.content-inicio .card .gerenciar .gerenciar-chapa .column img {
    max-width: 200px;
    height: auto;
    margin: 15px 0px 0px 0px;
    border-radius: 10px;
}


.content-inicio .card .gerenciar .flex {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.content-inicio .card .gerenciar .formulario .input {
    margin: 5px auto;
}

.content-inicio .card .gerenciar .formulario .input input {
    margin: 12.5px auto;
}

.content-inicio .card .gerenciar .inputs {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start !important;
    flex-wrap: wrap !important;
    gap: 0px;
    padding: 0px;
    margin: 15px 0px;
}

.content-inicio .card .gerenciar .inputs .input {
    width: auto;
    min-width: 26%;
    max-width: 32%;
    margin: 0px 12px 5px 0px;
}

.content-inicio .card .gerenciar .inputs .input-file {
    width: auto;
    min-width: 35%;
    margin: 0px 0px 5px 0px;
}

.content-inicio .card .gerenciar button {
    width: max-content;
    height: 50px;
    padding: 0px 17.5px;
    background: var(--blue);
    box-shadow: 4px 4px 2px var(--bluelight);
    border: 2px solid var(--blue);
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.2px;
    color: var(--white);
    margin: 0px auto 0px 20px;
    cursor: pointer;
    border-radius: 5px;
    outline: none;
    transition: all .1s ease-in-out;
} .content-inicio .card .gerenciar button:hover,
 .content-inicio .card .gerenciar button:focus {
    background: transparent;
    color: var(--blue);
    box-shadow: 4px 4px 2px var(--blue);
}

.content-inicio .card .gerenciar .btn-vermelho {
    background: var(--red);
    border: 2px solid var(--red);
    box-shadow: 4px 4px 2px red;
} .content-inicio .card .gerenciar .btn-vermelho:hover,
 .content-inicio .card .gerenciar .btn-vermelho:focus {
    background: transparent;
    color: var(--red);
    box-shadow: 4px 4px 2px var(--red);
}

.content-inicio .card .gerenciar .btn-branco {
    background: var(--white);
    border: 2px solid red;
    color: var(--red);
    box-shadow: 4px 4px 2px red;
} .content-inicio .card .gerenciar .btn-branco:hover,
 .content-inicio .card .gerenciar .btn-branco:focus {
    background: var(--red);
    color: var(--white);
    border: 2px solid var(--white);
    box-shadow: 4px 4px 2px var(--white);
}

/* ESCOLAS */
.content-inicio .card .gerenciar .column.escola {
    width: auto;
    height: auto;
    margin: 12.5px 0px;
}

.content-inicio .card .gerenciar .column.escola h1 {
    font-size: 19px;
    font-weight: 700;
    margin: 2.5px 0px;
    color: var(--three);
}

.content-inicio .card .gerenciar .column.escola p {
    font-size: 16px;
    font-weight: 500;
    margin: 2.5px 0px;
    color: var(--three);
}



*.bola {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: var(--three);
    margin: 0px 8px 0px 0px;
} *.bola.vermelha {
    background: var(--red);
} *.bola.verde {
    background: var(--green);
} *.bola.amarela {
    background: var(--yellow);
}